body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* main margin responsive */
main {
  margin-top: 150px;
}
@media (max-width: 800px) {
  main {
    margin-top: 90px;
  }
}

html {
  /* ScrollBar Mozilla */
  scrollbar-color: #F5377B #FFF!important;
  scrollbar-width: thin;
}

/* ScrollBar Other*/
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  background: #F5377B;
}

/* update style for react-smart-slide*/
.slideContainer {
  border: none!important;
}

/* Quicksand Font*/
@font-face {
  font-family: 'Quicksand';
  font-weight: 300;
  src: url('../fonts/Quicksand/Quicksand-Light.ttf');
}
@font-face {
  font-family: 'Quicksand';
  font-weight: normal;
  src: url('../fonts/Quicksand/Quicksand-Regular.ttf');
}
@font-face {
  font-family: 'Quicksand';
  font-weight: bold;
  src: url('../fonts/Quicksand/Quicksand-Bold.ttf');
}